<template>
  <div v-if='payStatus == "paid"'
    id='receipt-print' class='receipt-print relative border' style='height: 10.5in; width: 7.5in;'>
    <div class=''>
      <div class='bg-gray-100 w-full p-12'>
        <h1 class='text-5xl font-bold text-gray-700 text-center'>Receipt</h1>
      </div>
      <div 
        class='flex flex-col justify-center items-center gap-y-1 z-10 p-4 relative'
        style='font-family: serif;'>
        <img :src='watermarkImage' id='watermark-image' class='z-0 top-12 p-4 absolute opacity-10 w-1/2'/>
        <div class='w-full text-2xl px-8 mt-16'>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>Issue Date</div>
            <div class='text-right'>{{issueDate}}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>Name</div>
            <div class='text-right'>{{nameString}}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>Organization</div>
            <div class='text-right'>{{hospitalName}}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>Registration Fee</div>
            <div class='text-right'>{{registrationFee}}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t border-b'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>Contents</div>
            <div class='text-right'>Registration for KOA 2022</div>
          </div>

          <div class='text-base p-16 text-center'>
            This is to verify that the above individual has paid for registraiton for the 66<sup>th</sup> Annual Congress of the KOA to be held October 13-15, 2022.
          </div>
          <div class='text-2xl font-semibold text-center' style='font-family: sans-serif;'>
            The Korean Orthopaedic Association 
            <img :src='sealImage' id='seal-image' class='inline-block'/>
          </div>
        </div>
      </div>
      <div class='bg-gray-50 p-8 absolute bottom-0 left-0 right-0'>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-40 flex-shrink-0'>Contact Number</div>
          <div>+82-2-780-2766</div>
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-40 flex-shrink-0'>Email</div> 
          ortho@koa.or.kr
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-40 flex-shrink-0'>Fax</div> 
          +82-2-780-2767
        </div>
        <div class='mt-4 text-sm flex flex-col lg:flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-full lg:w-40 flex-shrink-0'>Address</div> 
          A-905, Centreville Asterium Seoul, 372 Hangang-daero, Yongsan-gu Seoul, 04323, Korea
        </div>
      </div>
    </div>
  </div>
  <div v-else-if='payStatus == "unpaid"'>
     <div class='max-w-2xl p-24'>This participant hasn't paid yet</div>
  </div>
  <div v-else-if='payStatus == "unknown"'>
    <div class='max-w-2xl p-24'>Please check your license number and name</div>
  </div>
  <div v-else-if='payStatus == "unknown"'>
    <div class='max-w-2xl p-24'>Please check your license number and name</div>
  </div>
  <div v-else>
    <div class='max-w-2xl p-24'><loader-icon class='spinner inline-block' /> Loading</div>
  </div>
</template>

<script>
import { LoaderIcon } from 'vue-tabler-icons'
import { mapActions } from 'vuex'
import html2pdf from 'html2pdf.js'
import dayjs from 'dayjs'
import imageToBase64 from 'image-to-base64/browser'

export default {
  name: 'Koa2022ReceiptSingleFile',
  components: {
    LoaderIcon,
  },
  props: [
    'layout',
  ],
  data () {
    return {
      receiptData: {},
      payStatus: '',
    }
  },
  computed: {
    pdfOptions () {
      return {
        margin:       0,
        filename:     `${this.receiptData.name}_receipt.pdf`,
        image:        { type: 'png', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      }
    },
    issueDate () {
      return `${dayjs().format('YYYY-MM-DD')}`
    },
    registrationFee () {
      return this.receiptData.registrationData.registrationFee
    },
    nameString () {
      return `${this.receiptData.firstName} ${this.receiptData.lastName} (${this.receiptData.name})`
    },
    hospitalName () {
      return this.receiptData.hospitalName
    },
    watermarkImage () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/koa2022/KOA_circle_white.png`
    },
    sealImage () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/koa2022/koa_korean_seal.png`
    }
  },
  methods: {
    ...mapActions('users', [
      'getPaidRegistrationDetails',
    ]),
    pdfCreate () {
      var element = document.getElementById('receipt-print')
      html2pdf().set(this.pdfOptions).from(element).save()
    },
    base64Images () {
      const watermarkPromise = imageToBase64(this.watermarkImage)
      const sealPromise = imageToBase64(this.sealImage)

      Promise.all([watermarkPromise, sealPromise]).then(values => {
        let image1 = document.getElementById('watermark-image')
        let image2 = document.getElementById('seal-image')
        let imagesLoadedCount = 0

        image1.src = `data:image/jpg;base64, ${values[0]}`
        image2.src = `data:image/jpg;base64, ${values[1]}`
        
        image1.onload = () => {
          imagesLoadedCount++
          if (imagesLoadedCount == 2) {
            this.pdfCreate()
          }
        }
        image2.onload = () => {
          imagesLoadedCount++
          if (imagesLoadedCount == 2) {
            this.pdfCreate()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getPaidRegistrationDetails({
      'licenseNumber': this.$route.query.licenseNumber,
      'name':          this.$route.query.name
    }).then(resp => {
      if (resp.participantUnknown) {
        this.payStatus = 'unknown'
      } else if (resp.registrationData.payment) {
        this.receiptData = resp
        this.payStatus = 'paid'
        this.base64Images()
      } else {
          this.payStatus = 'unpaid'
      }
    })
    
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss'>
  .receipt-print {
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
</style>
<style lang='scss' scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>